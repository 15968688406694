import React from "react";
import Layout from "components/layout";
import Seo from "components/seo";

function UserAgreement() {
  return (
    <Layout>
      <Seo title="User Agreement" />
      <section className="w-full bg-[#1D1C1C] p-5 pb-24 font-primary">
        <div className="border-2 border-[#2664e4] text-white">
          <p className="w-ful bg-[#2664E4] text-17 font-medium text-center uppercase mt-5 mb-3 py-1">
            USER AGREEMENT
          </p>
          <div className="p-5">
            <h3 className="text-[22px] font-medium uppercase my-10">
              STANDARD User AGREEMENT
            </h3>
            <div className="mb-16 text-[20px] space-y-4">
              <p className="font-light">
                This document is a legal agreement between you, the "User" (as
                defined below) and Tomedes. These User's Terms and Conditions
                ("Agreement") sets forth the provisions under which the User may
                register and use the web-site for the purpose of responding to
                solicitations for the acquisition of solutions, services, and
                products ,providing these solutions, services, and products and
                taking care of requested revisions of the software solutions,
                services, and products.
              </p>
              <p className="font-light">
                <span className="font-normal">DEFINITIONS Site (s):</span>{" "}
                Tomedes Ltd: (if applicable) is the parent company and owner of
                the site hereinafter referred to as Tomedes.{" "}
                <span className="font-normal">User:</span> Individual (s) or a
                Company posting a project.
              </p>
              <p className="font-light">
                <span className="font-normal">Provider:</span> Individual (s) or
                a Company providing a service.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">1. TERM</h4>
              <p className="font-light text-[20px]">
                The initial term of this Agreement shall be a period of one (1)
                year from the Effective Date. Thereafter, the Agreement shall
                automatically renew for subsequent one (1) year terms. At any
                time, either party may terminate this agreement, with or without
                cause, upon thirty (30) days prior written notice. Tomedes
                reserves the right to immediately terminate any User who commits
                fraud, slander, a denial-of-service attack, or commits any other
                action which it deems inappropriate. Neither party has made any
                commitments regarding the duration or renewal of this Agreement
                beyond those stated herein.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">2. SCOPE</h4>
              <p className="font-light text-[20px]">
                Upon completion of the Registration Form and after accepting the
                site Terms of Service and this agreement, the User is authorized
                to postTranslation Requests. This is a master agreement between
                User and Tomedes and shall apply to all transactions and
                business conducted between the parties.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">
                3. User's OBLIGATIONS
              </h4>
              <p className="font-light text-[20px]">
                The User will post a translation request, including all relevant
                information and/or documents in relation with his request. The
                User will process payment as requested by Tomedes before
                starting the translation process.
              </p>
            </div>
            <div className="mb-16">
              <p className="font-light text-[20px] mb-4">
                4. This Agreement shall govern the business relationship between
                Tomedes and User despite any different or conflicting terms and
                conditions in User's forms or other documents.
              </p>
              <p className="font-light text-[20px]"></p>
              <h4 className="font-medium text-[22px] mb-2">4.1. GENERAL</h4>
              <ul>
                <li>
                  <p className="font-light text-[20px] mb-4">
                    o <span className="font-normal">The User authorizes </span>{" "}
                    Tomedes to treat any person using User's password pair
                    (username and password), and any resulting transactions,
                    obligations and liabilities as if User used the website
                    and/or services itself.
                  </p>
                </li>
                <li>
                  <p className="font-light text-[20px] mb-4">
                    o <span className="font-normal">User must not</span>
                    try to expose the identity of other Users or of Providers.
                    The identity of Providers and of other Users is not visible
                    to the Users. The User must not post any information which
                    may reveal his identity to Providers or other Users. Posting
                    contact information including but not limited to phone #s,
                    email addresses, Internet URL's, Yahoo Messenger Ids, ICQ
                    ids is strictly prohibited.
                  </p>
                </li>
              </ul>
              <h4 className="font-medium text-[22px] mb-2">
                4.2. REGISTRATION
              </h4>
              <ul>
                <li>
                  <p className="font-light text-[20px] mb-4">
                    o <span className="font-normal">User's information</span>{" "}
                    shall be true, accurate and up-to-date at all times.
                  </p>
                </li>
              </ul>
              <h4 className="font-medium text-[22px] mb-2">4.3. PAYMENT</h4>
              <ul>
                <li>
                  <p className="font-light text-[20px] mb-4">
                    o Payment will be processed by the User prior to commencing
                    the translation process. Missing or incomplete payment will
                    cause a delay in starting and therefore a delay in
                    delivering the completed translation.
                  </p>
                </li>
                <li>
                  <p className="font-light text-[20px] mb-4">
                    o The User is the only responsible party for a delay caused
                    by the above reason.
                  </p>
                </li>
              </ul>
              <p className="text-[20px] font-normal">
                Violators of any of these terms can have all of their Tomedes
                accounts terminated, suspended or revoked at the discretion of
                Tomedes.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">
                5. Refund/Cancellation policy
              </h4>
              <p className="font-light text-[20px] mb-4">
                1. Any cancellation request after an order has been processed
                must be submitted in writing to the following email address:
                support@tomedes.com
              </p>
              <p className="font-light text-[20px] mb-4">
                2. If the company has not yet started the translation a full
                cancellation and refund will be granted. Should the translation
                already be in process, it will be stopped at the earliest
                opportunity (taking possible time zone hour differences into
                consideration). The client will only be charged for the part of
                the translation which has been completed.
              </p>
              <p className="font-light text-[20px]">
                3. Our goal is to provide a service of exceptional quality at a
                great value. For this reason, we offer a 1 year guarantee on all
                of our language services. Should you have any concern or are not
                satisfied with the final product, please contact our customer
                service at "email" or "phone Nr." Our translator will review the
                translation according to the client’s comments and offer
                feedback. Any agreed upon errors will be edited at no extra
                charge to the client. In certain cases, where we fail to meet an
                agreement with the client, a partial or full refund may be
                offered.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">
                6. User's REPRESENTATIONS.
              </h4>
              <p className="font-light text-[20px]">
                User represents that it has the full power and authority to
                execute this Agreement. User is the owner of, and/or has
                received all appropriate consent from the owners of, any and all
                material, information, intellectual property in any form, or
                other data that User makes available to Tomedes.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">7. TAXES</h4>
              <p className="font-light text-[20px]">
                Tomedes does not collect taxes, duties, fees or other
                governmentally imposed excises, so User and Provider agree to
                assume their respective responsibilities under the law
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">
                8. TRADEMARKS AND CONFIDENTIAL INFORMATION.
              </h4>
              <p className="font-light text-[20px] mb-4">
                Any information the User disclosed to the providers is
                considered confidential unless indicated otherwise
              </p>
              <p className="font-light text-[20px]">
                Return of Confidential Information. In any event of termination
                or expiration of this Agreement, each party will, within five
                (5) business days, return to the other party such party's
                Confidential Information.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">
                9. INTELLECTUAL PROPERTY INFRINGEMENT INDEMNITY
              </h4>
              <p className="font-light text-[20px]">
                Infringement Claims. The Provider will defend and hold Tomedes
                harmless in any suit or proceeding based on a claim that any
                equipment, products or services transacted under this Agreement,
                constitutes infringement of any copyright, patent or trademark.
                Provider will pay all settlements or damages awarded against
                Tomedes, provided that Tomedes (i) informs Provider of such suit
                or proceeding in writing and within thirty (30) days of actual
                notice of a claim, and provides Provider with all related
                information, (ii) grants Provider the authority to settle or
                litigate such suit or proceeding, and (iii) provides all
                necessary assistance to Provider.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">
                10. LIMITATION OF LIABILITY; INDEMNITY.
              </h4>
              <p className="font-light text-[20px] uppercase mb-4">
                IN NO EVENT SHALL TOMEDES BE LIABLE TO BUYERS, PROVIDERS OR ANY
                OTHER PERSON FOR EXEMPLARY, INCIDENTAL, INDIRECT, SPECIAL OR
                CONSEQUENTIAL DAMAGES OF ANY KIND, INCLUDING WITHOUT LIMITATION
                THE COST OF COVER, LOSS OF PROFIT, USE, SAVINGS OR REVENUE, OR
                THE CLAIMS OF THIRD PARTIES, WHETHER OR NOT TOMEDES HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH LOSS. THE LIMITATIONS IN THIS
                SECTION SHALL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL
                PURPOSE OF ANY LIMITED REMEDY.
              </p>
              <p className="font-light text-[20px]">
                <span className="font-normal">
                  Indemnification by Provider.
                </span>
                Provider shall indemnify, defend (with counsel approved in
                writing by Tomedes) and hold Tomedes, its officers, directors,
                shareholders, employees, insurers, attorneys, successors and
                assigns harmless against any and all claims for cost, damage,
                expense (including reasonable attorneys - fees) or liability
                arising out of or related to the acts or omissions of Provider,
                its employees, representatives or agents.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">
                11. DEFAULT AND TERMINATION.
              </h4>
              <p className="font-light text-[20px] mb-4">
                Default. Should the User default, their account may be
                terminated by Tomedes. User shall be considered to have
                defaulted under the Agreement for any failure to perform its
                obligations and/or if Tomedes discovers any material
                misrepresentation or omission that User has made in this
                Agreement or its Registration. In the case of either party, the
                failure by such party to observe or perform any material
                covenant or obligation under this Agreement shall constitute
                default. User may also be considered in default if they violate
                the site terms of service..
              </p>
              <p className="font-light text-[20px]">
                Survival. The parties' obligations under Sections 6, 7 and 8
                shall survive any termination and/or expiration of this
                Agreement.
              </p>
            </div>
            <div className="mb-16">
              <h4 className="font-medium text-[22px] mb-2">12. GENERAL.</h4>
              <p className="font-light text-[20px] mb-4">
                Assignment. User shall not assign or transfer this Agreement
                without Tomedes's prior written consent, which shall not be
                unreasonably withheld. Any attempted assignment shall be null
                and void.
              </p>
              <p className="font-light text-[20px] mb-4">
                Relationship of the Parties. This Agreement does not create a
                franchise, joint venture or partnership between the parties.
                Neither party hereunder is the agent, broker, partner, employee,
                or legal representative of the other for any purpose.
              </p>
              <p className="font-light text-[20px] mb-4">
                Excused Performance. Neither party shall be liable for failure
                to perform its obligations under this Agreement for causes
                beyond its reasonable control.
              </p>
              <p className="font-light text-[20px] mb-4">
                No Waiver. The failure of either party to enforce any of the
                provisions hereof shall not be construed to be a waiver of the
                right of such party to thereafter enforce any such provision.
              </p>
              <p className="font-light text-[20px] mb-4">
                Unenforceable Provisions. If any term of this Agreement is found
                to be illegal or unenforceable, the remaining portions of this
                Agreement shall remain in effect, provided that the parties
                agree to negotiate in good faith substitute enforceable terms.
              </p>
              <p className="font-light text-[20px] mb-4">
                Modifications; Special Agreements; Entire Agreement. Note that
                Tomedes reserves the right from time to time to amend, modify or
                change this agreement. Due to the majority of parties requesting
                not to be contacted via email about updates to the site, email
                notice will not always be given. Additionally, due to the
                financial costs associated with written notices...written notice
                will not always be given. If you are interested in keeping
                abreast of the latest changes to this agreement, then please
                bookmark this page and review it from time to time.
              </p>
              <p className="font-light text-[20px] uppercase mb-4">
                IF ANY MODIFICATION IS UNACCEPTABLE TO YOU, YOUR ONLY RECOURSE
                IS TO TERMINATE THIS AGREEMENT BY PROVIDING NOTICE TO US. YOUR
                CONTINUED PARTICIPATION IN THE PROGRAM FOLLOWING OUR POSTING OF
                A CHANGE NOTICE OR NEW AGREEMENT ON OUR SITE WILL CONSTITUTE
                BINDING ACCEPTANCE OF THE CHANGE.
              </p>
              <p className="font-light text-[20px] mb-4">
                Should Tomedes and the User come to a special understanding not
                outlined in this agreement it will be laid out in writing and
                signed by both parties.
              </p>
              <p className="font-light text-[20px]">
                This Agreement is the entire understanding between User and
                Tomedes with respect to the subject matter of this Agreement,
                and supersedes all (i) proposals, oral or written, (ii)
                negotiations, conversations or discussions between the parties
                and (iii) industry custom or past course of dealing, relating to
                the subject matter.
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default UserAgreement;
